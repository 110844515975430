@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GtAmericasLight';
  src        : local('GtAmericasLight'),
    url(../../public/fonts/GtAmericasLight.otf) format('opentype');
}

@font-face {
  font-family: 'GtAmerica';
  src        : local('GtAmerica'),
    url(../../public/fonts/GT\ America\ Regular.otf) format('opentype');
}

[role='dialog'] {
  z-index: 9999 !important;
}
